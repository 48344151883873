import * as React from "react"

function NewAppInvento(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="90 420 800 900"
      aria-label="No Code App Builder Platform"
      style={{
        enableBackground: "new 0 0 1000 1000",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n    .st0 {\n      fill: url(#SVGID_1_);\n    }\n\n    .st1 {\n      fill: url(#SVGID_2_);\n    }\n\n    .st2 {\n      fill: #121212;\n      stroke: url(#SVGID_3_);\n      stroke-width: 11;\n      stroke-miterlimit: 10;\n    }\n\n    .st3 {\n      fill: url(#SVGID_4_);\n    }\n\n    .st4 {\n      fill: #FFFFFF;\n    }\n\n    .st5 {\n      fill: url(#SVGID_5_);\n    }\n\n    .st6 {\n      fill: url(#SVGID_6_);\n    }\n\n    .st7 {\n      fill: url(#SVGID_7_);\n    }\n\n    .st8 {\n      fill: #121212;\n      stroke: url(#SVGID_8_);\n      stroke-width: 11;\n      stroke-miterlimit: 10;\n    }\n\n    .st9 {\n      fill: url(#SVGID_9_);\n    }\n\n    .st10 {\n      fill: #020202;\n    }\n\n    .st11 {\n      fill: url(#SVGID_10_);\n    }\n  "
        }
      </style>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={134.3199}
          y1={540.4828}
          x2={233.7652}
          y2={462.4754}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#94CE98",
            }}
          />
          <stop
            offset={0.2588}
            style={{
              stopColor: "#89C99F",
            }}
          />
          <stop
            offset={0.724}
            style={{
              stopColor: "#6BBFB2",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#51B9BE",
            }}
          />
        </linearGradient>
        <polygon
          className="st0"
          points="191.81,473.31 141.33,502.81 191.81,532.31 191.81,547.5 117.76,504.29 117.75,501.37 191.81,458.16  "
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={-4590.1929}
          y1={545.3394}
          x2={-4684.2979}
          y2={460.9666}
          gradientTransform="matrix(-1 0 0 1 -4347.0146 0)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#94CE98",
            }}
          />
          <stop
            offset={0.2354}
            style={{
              stopColor: "#8CCA9D",
            }}
          />
          <stop
            offset={0.6264}
            style={{
              stopColor: "#74C1AD",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#51B9BE",
            }}
          />
        </linearGradient>
        <polygon
          className="st1"
          points="277.66,473.31 328.14,502.81 277.66,532.31 277.66,547.5 351.71,504.29 351.72,501.37 277.66,458.16  "
        />
      </g>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1={219.4759}
        y1={531.496}
        x2={252.433}
        y2={474.4126}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2209}
          style={{
            stopColor: "#8ECB9C",
          }}
        />
        <stop
          offset={0.5406}
          style={{
            stopColor: "#7CC4A7",
          }}
        />
        <stop
          offset={0.9187}
          style={{
            stopColor: "#5BBBBA",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <line className="st2" x1={211.47} y1={524.9} x2={260.44} y2={481.01} />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1={215.6077}
        y1={536.0827}
        x2={253.8604}
        y2={469.8272}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2209}
          style={{
            stopColor: "#8ECB9C",
          }}
        />
        <stop
          offset={0.5406}
          style={{
            stopColor: "#7CC4A7",
          }}
        />
        <stop
          offset={0.9187}
          style={{
            stopColor: "#5BBBBA",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <path
        className="st3"
        d="M234.73,464.71c-21.12,0-38.24,17.13-38.24,38.25c0,0.67,0.02,1.35,0.05,2.01 c1.04,20.19,17.74,36.24,38.19,36.24c20.45,0,37.15-16.05,38.2-36.24c0.03-0.66,0.05-1.33,0.05-2.01 C272.98,481.83,255.85,464.71,234.73,464.71z M234.62,530.22c-14.41,0-26.22-11.12-27.31-25.26c-0.05-0.71-0.08-1.42-0.08-2.13 c0-15.12,12.27-27.39,27.39-27.39c15.13,0,27.39,12.26,27.39,27.39c0,0.72-0.03,1.43-0.08,2.13 C260.84,519.1,249.03,530.22,234.62,530.22z"
      />
      <g>
        <path
          className="st4"
          d="M424.31,519.41c-4.59,5.36-11.38,8.79-18.97,8.79c-13.78,0-24.96-11.32-24.96-25.26s11.18-25.26,24.96-25.26 s24.96,11.32,24.96,25.26v25.26h-5.99V519.41z M405.34,522.14c10.18,0,18.97-8.08,18.97-19.2c0-10.61-8.49-19.2-18.97-19.2 s-18.97,8.59-18.97,19.2S394.86,522.14,405.34,522.14z"
        />
        <path
          className="st4"
          d="M436.3,477.68h5.99v8.89c4.59-5.46,11.38-8.89,18.97-8.89c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.26-24.96,25.26c-7.59,0-14.38-3.44-18.97-8.79v34.06h-5.99V477.68z M461.26,522.14c10.48,0,18.97-8.59,18.97-19.2 s-8.49-19.2-18.97-19.2c-10.29,0-18.97,8.19-18.97,19.2C442.29,513.55,450.78,522.14,461.26,522.14z"
        />
        <path
          className="st4"
          d="M492.22,477.68h5.99v8.89c4.59-5.46,11.38-8.89,18.97-8.89c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.26-24.96,25.26c-7.59,0-14.38-3.44-18.97-8.79v34.06h-5.99V477.68z M517.18,522.14c10.48,0,18.97-8.59,18.97-19.2 s-8.49-19.2-18.97-19.2c-10.29,0-18.97,8.19-18.97,19.2C498.21,513.55,506.7,522.14,517.18,522.14z"
        />
      </g>
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1={596.5914}
        y1={496.5233}
        x2={837.0388}
        y2={481.7866}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2151}
          style={{
            stopColor: "#87C8A0",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <path
        className="st5"
        d="M847.28,529.2c-14.32,0-25.97-11.78-25.97-26.26c0-14.48,11.65-26.26,25.97-26.26s25.97,11.78,25.97,26.26 C873.25,517.42,861.6,529.2,847.28,529.2z M847.28,484.74c-9.91,0-17.97,8.17-17.97,18.2c0,10.04,8.06,18.2,17.97,18.2 s17.97-8.16,17.97-18.2C865.25,492.91,857.19,484.74,847.28,484.74z M790.36,529.2v-77.79h7.99v25.26h21.97v8.06h-21.97v44.46 H790.36z M774.38,529.2v-26.26c0-10.04-8.06-18.2-17.97-18.2c-9.58,0-17.97,8.5-17.97,18.2v26.26h-7.99v-52.53h7.99v7.35 c4.82-4.7,11.24-7.35,17.97-7.35c14.32,0,25.97,11.78,25.97,26.26v26.26H774.38z M696.49,529.2c-14.32,0-25.97-11.78-25.97-26.26 c0-14.48,11.65-26.26,25.97-26.26s25.97,11.78,25.97,26.26v4.03h-43.53c1.88,8.28,9.09,14.17,17.56,14.17 c4.75,0,9.37-1.92,12.67-5.26l0.69-0.7l5.73,5.52l-0.7,0.72C710.04,526.44,703.5,529.2,696.49,529.2z M713.97,498.91 c-1.79-8.28-8.96-14.17-17.47-14.17c-8.47,0-15.68,5.89-17.56,14.17H713.97z M639.22,529.2l-23.05-52.53h8.58l18.32,41.67 l18.32-41.67h8.67l-23.05,52.53H639.22z M607.72,529.2v-26.26c0-10.04-8.06-18.2-17.97-18.2c-9.58,0-17.98,8.5-17.98,18.2v26.26 h-7.99v-52.53h7.99v7.35c4.82-4.7,11.24-7.35,17.98-7.35c14.32,0,25.97,11.78,25.97,26.26v26.26H607.72z M547.9,529.2v-52.53h7.99 v52.53H547.9z M547.9,463.52v-12.11h7.99v12.11H547.9z"
      />
    </svg>
  )
}

export default React.memo(NewAppInvento);
