/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-intl'

import * as styles from './styles.module.scss';

const BUTTON_STYLES = ['primary', 'secondary', 'outlineWhite', 'dark']

const buttonLoadingComponent = 'Wait...'

const Button = ({
  type,
  htmlType,
  onClick,
  disabled,
  loading,
  small,
  className,
  children,
  href,
  linkId,
  linkClass,
  ...props
}) => {
  const buttonProps = {
    type: htmlType,
    onClick,
    className: classNames(
      styles.btn,
      styles[type],
      { [styles.small]: small },
      { [styles.disabled]: disabled },
      { [styles.loading]: loading },
      className
    ),
    disabled: disabled || !!loading,
    ...props,
  }

  const setButtonChildren = () => (loading ? buttonLoadingComponent : children)

  const internal = /^\/(?!\/)/.test(href);

  if (href) {
    if (internal) {
      return (
          <Link to={href} id={linkId} className={linkClass} aria-label="Services">
            <button {...buttonProps}>
              <span className={styles.btnText}>{setButtonChildren()}</span>
              <span className={styles.btnHighlight}/>
            </button>
          </Link>
      )
    } else {
      return (
          <a href={href} id={linkId} target="_blank" className={linkClass} aria-label="Services">
            <button {...buttonProps}>
              <span className={styles.btnText}>{setButtonChildren()}</span>
              <span className={styles.btnHighlight}/>
            </button>
          </a>
      )
    }
  }

  return (
    <button {...buttonProps}>
      <span className={styles.btnText}>{setButtonChildren()}</span>
      <span className={styles.btnHighlight} />
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(BUTTON_STYLES),
  htmlType: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  linkId: PropTypes.string,
  linkClass: PropTypes.string,
}

Button.defaultProps = {
  type: 'primary',
  htmlType: 'button',
  onClick: () => {},
  disabled: false,
  loading: false,
  small: false,
  className: undefined,
  href: undefined,
  linkId: undefined,
  linkClass: undefined,
}

export default Button
