import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { scrollTo } from '_utils/scroll'
import Arrow from '_images/svgs/scroll-arrow.inline'

import Text from '../text'

import * as styles from './styles.module.scss';
import Button from "../button";
import {navigate} from "gatsby";

const ScrollArrow = ({ text, textColor, className, isButton, scrollId, onclickArrow }) => (
    <div className={classnames(styles.scrollContainer, className)}>
  {text && <Text color={textColor}>{text}</Text>}
    <div className={classnames(styles.scrollArrow, { [styles.withText]: text })}>
      {isButton ? (
        <a href='https://app.appinvento.io/' target='_blank' aria-label="scrollLink"><button
          type="button"
          className={styles.scrollArrowButton}
          // onClick={() => scrollTo(scrollId)}
        >
          <Arrow className={styles.scrollRightButton}/>
        </button></a>
      ) : (
          <a href='https://app.appinvento.io/' target='_blank' aria-label="scrollLink">
          <Arrow fillColor={textColor} className={styles.scrollRightButton}/>
          </a>
      )}
    </div>
  </div>
)

ScrollArrow.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  isButton: PropTypes.bool,
  scrollId: PropTypes.string,
}

ScrollArrow.defaultProps = {
  className: '',
  text: '',
  textColor: 'white',
  isButton: false,
  scrollId: undefined,
}

export default ScrollArrow
