var React = require('react');

function BrandSymbolInline (props) {
    return React.createElement("svg",props,[React.createElement("path",{"d":"M0.0362212 1.9762L25.1074 9.65698L20.7236 10.9975L0.0362212 4.65722V7.33824L16.3398 12.338L11.9559 13.6785L0 10.0193V12.7003L7.57207 15.019L0 17.3378V20.0188L29.455 10.9975V9.76567C29.455 8.89614 28.8754 8.13531 28.0421 7.8817L2.53611 0.0922267C1.30428 -0.306304 0.0362212 0.635682 0.0362212 1.9762Z","fill":"white","key":0}),React.createElement("path",{"d":"M0.0283203 25.3449L29.4834 16.3236V13.6426L0.0283203 22.6639V25.3449Z","fill":"white","key":1}),React.createElement("path",{"d":"M2.5781 29.91L28.0841 22.1206C28.9174 21.867 29.497 21.1061 29.497 20.2366V19.041L0.0419922 28.0623C0.0419922 29.3304 1.31004 30.3086 2.5781 29.91Z","fill":"white","key":2})]);
}

BrandSymbolInline.defaultProps = {"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none"};

export default BrandSymbolInline;

BrandSymbolInline.default = BrandSymbolInline;
