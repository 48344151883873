import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Text from '../text'

import * as styles from './styles.module.scss';

const Select = ({
  id,
  label,
  name,
  placeholder,
  value,
  options,
  onChange,
  className,
  onBlur,
  error,
  success,
  required,
  disabled,
  errorMessage,
  ...props
}) => {
  const [touched, setTouched] = useState(false)

  const handleChange = event => {
    setTouched(true)
    onChange(event)
  }

  const requiredEmpty = touched && required && !value

  const selectProps = {
    id,
    label,
    name,
    placeholder,
    value,
    onChange: handleChange,
    onBlur,
    className: classNames(
      styles.select,
      {
        [styles.error]: error,
        [styles.success]: success,
        [styles.disabled]: disabled,
      },
      className
    ),
    disabled,
    ...props,
  }

  const labelProps = {
    className: classNames(styles.label, {
      [styles.labelError]: error || requiredEmpty,
      [styles.labelSuccess]: success,
    }),
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={name} {...labelProps}>
        {label}
      </label>
      <select {...selectProps}>
        {options.map(({ value: optValue, label: optLabel }) => (
          <option key={optValue} value={optValue}>
            {optLabel}
          </option>
        ))}
      </select>
      {(error || requiredEmpty) && (
        <Text size="12" className={styles.messageError}>
          {errorMessage || <FormattedMessage id="input.required" />}
        </Text>
      )}
    </>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

Select.defaultProps = {
  id: undefined,
  label: undefined,
  className: undefined,
  required: false,
  disabled: false,
  error: false,
  success: false,
  value: undefined,
  options: [],
  errorMessage: undefined,
  onChange: () => {},
  onBlur: () => {},
}

export default Select
