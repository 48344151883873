const styles = {}

// colors
styles['color-white'] = '#FFFFFF'
styles['color-dark'] = '#1C1B20'
styles['color-gray-light'] = '#B0B2BA'
styles['color-gray-lightest'] = '#F3F3F7'
styles['color-dark-gray'] = '#323232'
styles['color-darkest-gray'] = '#262626'
styles['color-pale-blue'] = '#f4f5ff'
styles['color-white-smoke'] = '#f5f5f5'

// texts
styles['text-color-dark'] = styles['color-dark']
styles['text-color'] = '#555'
styles['text-disabled-color'] = '#999'
styles['text-color-title'] = styles['text-color-dark']

// Project colors
styles['color-primary'] = '#0058FF'
styles['color-blue-accent'] = '#45F3D0'
styles['color-blue-light'] = '#59ABF9'
styles['color-blue-medium'] = '#3E75F7'
styles['color-accent'] = '#8533D7'
styles['color-error'] = '#ff4444'
styles['color-success'] = '#00c851'
styles['color-disabled'] = '#DDDDDD'
styles['color-light-accent'] = '#CFCFCF'
styles['color-dark-bg'] = '#231F20'
styles['color-green-light'] = '#66E3DF'
styles['color-lightGreen']='#abd1c6'

// Defaults
styles['default-font-family'] = "'DM Sans', sans-serif"
styles['default-title-font-family'] = "'Poppins', sans-serif"
styles['default-text-color'] = styles['text-color']
styles['default-input-border-color'] = '#9F9F9F'
styles['default-squared-font-family'] = "'Sora', sans-serif"

// Preserved variables placeholders
styles['root-hover-bg'] = styles['color-white']
styles['root-hover-text'] = styles['color-primary']

// Projects
// - Tapcart variables -
styles['tapcart-primary'] = '#363EA9'
styles['tapcart-primary-light'] = '#3943B5'
styles['tapcart-accent'] = '#45F3D0'

// - Lockitron variables -
styles['lockitron-primary'] = '#8BD4FF'
styles['lockitron-secondary'] = '#489ACA'
styles['lockitron-accent'] = '#434544'
styles['lockitron-gray-accent'] = '#7c8b9b'

// - Skyroam variables -
styles['skyroam-primary'] = '#F05325'
styles['skyroam-secondary'] = '#555759'
styles['skyroam-gray'] = '#F3F3F7'

// - FindHeli variables -
styles['findheli-blue-primary'] = '#091a51'
styles['findheli-blue-light'] = '#EBECF1'
styles['findheli-blue-secondary'] = '#07174a'
styles['findheli-blue-accent'] = '#083D83'
styles['findheli-yellow-primary'] = '#FEBB01'

// - VPP variables -
styles['vpp-blue-primary'] = '#0075B0'
styles['vpp-green-accent'] = '#7AB800'

// - SU variables -
styles['su-primary'] = '#091B2F'
styles['su-gray'] = '#152639'
styles['su-pink'] = '#E2228D'
styles['su-blue'] = '#2A65AF'
styles['su-orange'] = '#FF7900'
styles['su-dark'] = '#1F263D'

// - Camio variables -
styles['camio-primary'] = '#ED962C'
styles['camio-secondary'] = '#4FB535'

// - EQI variables -
styles['eqi-primary'] = '#064474'
styles['eqi-secondary'] = '#033044'
styles['eqi-accent'] = '#FEBE3B'
styles['eqi-accent-dark'] = '#775A1E'

// - ExMox variables -
styles['exmox-primary'] = '#5458B5'
styles['exmox-primary-dark'] = '#303030'
styles['exmox-secondary'] = '#DFDFDF'
styles['exmox-secondary-light'] = '#F4F4F4'

// - Woovit variables -
styles['woovit-blue-primary'] = '#2A4158'
styles['woovit-blue-secondary'] = '#0C5687'
styles['woovit-blue-accent'] = '#1176B9'
styles['woovit-yellow'] = '#F2A615'
styles['woovit-teal'] = '#0BD0B4'
styles['woovit-light'] = '#F7FBFE'
styles['woovit-gradient'] = '#141d27'

// - Meli variables -
styles['meli-primary'] = '#F7E000'
styles['meli-secondary'] = '#283483'
// - Swfast variables -
styles['swfast-primary'] = '#3A7C29'
styles['swfast-yellow'] = '#ffff1a'
styles['swfast-green-light'] = '#90D346'
styles['swfast-green-dark'] = '#35AE32'
styles['swfast-stripe'] = 'rgba(255, 255, 255, 0.05)'

module.exports = styles
