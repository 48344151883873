import  _Arrow from './arrow'
import  _BlueText from './blue-text'
import  _Brand from './brand'
import  _Text from './text'
import  _Button from "./button";
import  _Checkbox from "./checkbox";
import  _Container from "./container";
import  _Heading from "./heading";
import  _Input from "./input";
import  _ScrollArrow from "./scroll-arrow";
import  _Select from "./select";
import  _SliderNav from "./slider-nav";
import  _Image from "./image";
import _SelectBox from "./selectBox";
import _ScreenLoader from "./screenLoader";


export const Arrow =  _Arrow;
export const BlueText =  _BlueText;
export const Brand =  _Brand;
export const Button =  _Button;
export const Checkbox =  _Checkbox;
export const Container =  _Container;
export const Heading =  _Heading;
export const Image =  _Image;
export const Input =  _Input;
export const ScrollArrow =  _ScrollArrow;
export const Select =  _Select;
export const SliderNav =  _SliderNav;
export const Text =  _Text;
export const SelectBox = _SelectBox;
export const ScreenLoader = _ScreenLoader;


// export {Arrow, BlueText, Brand, Button, Checkbox, Container, Heading, Image, Input, ScrollArrow, Select, SliderNav, Text}

