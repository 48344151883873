var React = require('react');

function ScrollArrowInline(props) {
  const {fillColor} = props;
  return React.createElement("svg", props, React.createElement("path", {
    "fillRule": "evenodd",
    "clipRule": "evenodd",
    "d": "M16.9992 27.0916L27.293 16.7979L28.7072 18.2121L16.0001 30.9192L3.29297 18.2121L4.70718 16.7979L14.9992 27.0898L14.9992 1H16.9992L16.9992 27.0916Z",
    "fill": fillColor
  }));
}

ScrollArrowInline.defaultProps = {"width":"100%","height":"100%","viewBox":"0 0 32 32","fill":"none"};

export default ScrollArrowInline;

ScrollArrowInline.default = ScrollArrowInline;
