// extracted by mini-css-extract-plugin
export var blueAccent = "styles-module--blueAccent--4b4a3";
export var bold = "styles-module--bold--436bc";
export var camioPrimary = "styles-module--camioPrimary--99d3d";
export var camioSecondary = "styles-module--camioSecondary--13f56";
export var eqiAccent = "styles-module--eqiAccent--b3ad4";
export var eqiPrimary = "styles-module--eqiPrimary--dcd1b";
export var exmoxPrimary = "styles-module--exmoxPrimary--84b90";
export var findHeliBluePrimary = "styles-module--findHeliBluePrimary--b4127";
export var h1 = "styles-module--h1--e0307";
export var h2 = "styles-module--h2--cc12d";
export var h3 = "styles-module--h3--5b984";
export var h4 = "styles-module--h4--45029";
export var h5 = "styles-module--h5--b0fad";
export var headings = "styles-module--headings--0de45";
export var lockitronAccent = "styles-module--lockitronAccent--8cea9";
export var primary = "styles-module--primary--82dd3";
export var responsive = "styles-module--responsive--b7f3c";
export var skyroamSecondary = "styles-module--skyroamSecondary--72b1c";
export var vppBluePrimary = "styles-module--vppBluePrimary--4ad94";
export var white = "styles-module--white--9dd1d";
export var woovitAccent = "styles-module--woovitAccent--0e1aa";
export var woovitPrimary = "styles-module--woovitPrimary--a1437";
export var woovitSecondary = "styles-module--woovitSecondary--a15ae";