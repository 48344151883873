import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss';

const SliderNav = ({ isWhite, className }) => (
  <span
    className={classNames('tns-nav-button', styles.nav, { [styles.white]: isWhite }, className)}
  />
)

SliderNav.propTypes = {
  isWhite: PropTypes.bool,
  className: PropTypes.string,
}

SliderNav.defaultProps = {
  isWhite: false,
  className: undefined,
}

export default SliderNav
