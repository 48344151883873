import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import BrandSymbol from '_images/svgs/brand-symbol.inline'
// import BrandType from '_images/svgs/brand-type.inline'
import AppInventoIcon from "../../../images/svgs/appInventoIcon";
import SvgComponent from "../../../images/svgs/appInventoFinal";
import AppInventoBlackTheme from "../../../images/svgs/appInvento-blackTheme";
import NewAppInvento from "../../../images/svgs/newAppInvento";
import AppInventoWhiteTheme from "../../../images/svgs/appInvento-whiteTheme";
import AppInvetoTypoBlackTheme from "../../../images/svgs/appInventTypo-blackTheme";
import AppInventoTypoWhiteTheme from "../../../images/svgs/appInventoTypo-whiteTheme";

import * as styles from './styles.module.scss';

const COLORS = ['white', 'blue', 'dark', 'exmoxPrimary']

const Brand = ({ compact, color }) => {
  return (
    <div className={classNames(styles.brand, styles[color], { [styles.compact]: compact })}>
      {/*<BrandSymbol className={styles.brandSymbol} />*/}
      {/*{(compact === false) ? '' : <AppInventoIcon/>}*/}
      {/*<AppInventoIcon className={styles.brandSymbol}/>*/}
      <div className={styles.brandTypeContainer}>
        {/*<BrandType />*/}
        {/*<SvgComponent/>*/}
        {/*<AppInventoBlackTheme/>*/}
        <NewAppInvento/>
        {/*<AppInventoWhiteTheme/>*/}
        {/*<AppInvetoTypoBlackTheme/>*/}
        {/*<AppInventoTypoWhiteTheme/>*/}
      </div>
    </div>
  )
}

Brand.propTypes = {
  compact: PropTypes.bool,
  color: PropTypes.oneOf(COLORS),
}

Brand.defaultProps = {
  compact: false,
  color: 'blue',
}

export default Brand
