import * as React from "react"

function AppInventoTypoWhiteTheme(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="90 420 800 900"
      style={{
        enableBackground: "new 0 0 1000 1000",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\r\n    .st0 {\r\n      fill: #020202;\r\n    }\r\n\r\n    .st1 {\r\n      fill: url(#SVGID_1_);\r\n    }\r\n  "
        }
      </style>
      <g>
        <path
          className="st0"
          d="M415.37,529.95c-4.59,5.36-11.38,8.79-18.97,8.79c-13.78,0-24.96-11.32-24.96-25.26s11.18-25.26,24.96-25.26 s24.96,11.32,24.96,25.26v25.26h-5.99V529.95z M396.4,532.68c10.18,0,18.97-8.08,18.97-19.2c0-10.61-8.49-19.2-18.97-19.2 s-18.97,8.59-18.97,19.2S385.92,532.68,396.4,532.68z"
        />
        <path
          className="st0"
          d="M427.36,488.21h5.99v8.89c4.59-5.46,11.38-8.89,18.97-8.89c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.26-24.96,25.26c-7.59,0-14.38-3.44-18.97-8.79V564h-5.99V488.21z M452.32,532.68c10.48,0,18.97-8.59,18.97-19.2 s-8.49-19.2-18.97-19.2c-10.29,0-18.97,8.19-18.97,19.2C433.35,524.09,441.84,532.68,452.32,532.68z"
        />
        <path
          className="st0"
          d="M483.28,488.21h5.99v8.89c4.59-5.46,11.38-8.89,18.97-8.89c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.26-24.96,25.26c-7.59,0-14.38-3.44-18.97-8.79V564h-5.99V488.21z M508.24,532.68c10.48,0,18.97-8.59,18.97-19.2 s-8.49-19.2-18.97-19.2c-10.29,0-18.97,8.19-18.97,19.2C489.27,524.09,497.76,532.68,508.24,532.68z"
        />
      </g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={587.6507}
        y1={507.0558}
        x2={828.0981}
        y2={492.3191}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2151}
          style={{
            stopColor: "#87C8A0",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <path
        className="st1"
        d="M838.34,539.74c-14.32,0-25.97-11.78-25.97-26.26s11.65-26.26,25.97-26.26s25.97,11.78,25.97,26.26 S852.66,539.74,838.34,539.74z M838.34,495.27c-9.91,0-17.97,8.17-17.97,18.2c0,10.04,8.06,18.2,17.97,18.2s17.97-8.16,17.97-18.2 C856.31,503.44,848.25,495.27,838.34,495.27z M781.42,539.74v-77.79h7.99v25.26h21.97v8.06h-21.97v44.46H781.42z M765.44,539.74 v-26.26c0-10.04-8.06-18.2-17.97-18.2c-9.58,0-17.97,8.5-17.97,18.2v26.26h-7.99v-52.53h7.99v7.35c4.82-4.7,11.24-7.35,17.97-7.35 c14.32,0,25.97,11.78,25.97,26.26v26.26H765.44z M687.55,539.74c-14.32,0-25.97-11.78-25.97-26.26s11.65-26.26,25.97-26.26 s25.97,11.78,25.97,26.26v4.03h-43.53c1.88,8.28,9.09,14.17,17.56,14.17c4.75,0,9.37-1.92,12.67-5.26l0.69-0.7l5.73,5.52l-0.7,0.72 C701.1,536.97,694.56,539.74,687.55,539.74z M705.03,509.44c-1.79-8.28-8.96-14.17-17.47-14.17c-8.47,0-15.68,5.89-17.56,14.17 H705.03z M630.28,539.74l-23.05-52.53h8.58l18.32,41.67l18.32-41.67h8.67l-23.05,52.53H630.28z M598.78,539.74v-26.26 c0-10.04-8.06-18.2-17.97-18.2c-9.58,0-17.98,8.5-17.98,18.2v26.26h-7.99v-52.53h7.99v7.35c4.82-4.7,11.24-7.35,17.98-7.35 c14.32,0,25.97,11.78,25.97,26.26v26.26H598.78z M538.96,539.74v-52.53h7.99v52.53H538.96z M538.96,474.05v-12.11h7.99v12.11H538.96 z"
      />
    </svg>
  )
}

export default AppInventoTypoWhiteTheme;
