var React = require('react');

function BigArrowRightInline (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M2.13281 2L21.1328 21L2.13281 40","stroke":"#78c7a8","strokeWidth":"4"}));
}

BigArrowRightInline.defaultProps = {"width":"24","height":"42","viewBox":"0 0 24 42","fill":"none"};

export default BigArrowRightInline;

BigArrowRightInline.default = BigArrowRightInline;
