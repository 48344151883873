// extracted by mini-css-extract-plugin
export var appInventoButton = "styles-module--appInventoButton--ede9f";
export var btn = "styles-module--btn--66fb4";
export var btnHighlight = "styles-module--btnHighlight--7cf00";
export var btnText = "styles-module--btnText--d3973";
export var dark = "styles-module--dark--f8a0a";
export var disabled = "styles-module--disabled--302ac";
export var loading = "styles-module--loading--0d2b3";
export var outlineWhite = "styles-module--outlineWhite--7256f";
export var primary = "styles-module--primary--46a48";
export var secondary = "styles-module--secondary--b1568";
export var small = "styles-module--small--8ad44";