import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Text from '../text'

import * as styles from './styles.module.scss';

const Input = ({
  id,
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  className,
  onBlur,
  error,
  success,
  required,
  disabled,
  textarea,
  errorMessage,
  ...props
}) => {
  const [touched, setTouched] = useState(false)

  const handleChange = event => {
    setTouched(true)
    onChange(event)
  }

  const handleBlur = event => {
    setTouched(true)
    onBlur(event)
  }

  const requiredEmpty = touched && required && !value

  const inputProps = {
    id,
    label,
    name,
    type,
    placeholder,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    className: classNames(
      styles.input,
      {
        [styles.error]: error || requiredEmpty,
        [styles.success]: success,
        [styles.disabled]: disabled,
        [styles.textarea]: textarea,
      },
      className
    ),
    disabled,
    ...props,
  }

  const labelProps = {
    className: classNames(styles.label, {
      [styles.labelError]: error || requiredEmpty,
      [styles.labelSuccess]: success,
    }),
  }

  const renderInput = () => (textarea ? <textarea {...inputProps} /> : <input {...inputProps} />)

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={name} {...labelProps}>
        {label}
      </label>
      {renderInput()}
      {(error || requiredEmpty) && (
        <Text size="12" className={styles.messageError}>
          {errorMessage || <FormattedMessage id="input.required" />}
        </Text>
      )}
    </>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  textarea: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Input.defaultProps = {
  id: undefined,
  label: undefined,
  className: undefined,
  placeholder: '',
  type: 'text',
  required: false,
  disabled: false,
  error: false,
  success: false,
  textarea: false,
  errorMessage: undefined,
  value: undefined,
  onChange: () => {},
  onBlur: () => {},
}

export default Input
