import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss';

const BlueText = ({ children, light, black, squared, className }) => {
  return (
    <span
      className={classNames(
        styles.blueText,
        { [styles.light]: light, [styles.black]: black, [styles.squared]: squared },
        className
      )}
    >
      {children}
    </span>
  )
}

BlueText.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  squared: PropTypes.bool,
  className: PropTypes.string,
}

BlueText.defaultProps = {
  light: false,
  squared: false,
  className: undefined,
}

export default BlueText
