import React from 'react'
import {InlineWidget} from "react-calendly";
import {Container} from '_atoms'
import * as styles from './styles.module.scss';
import {AiFillCloseCircle} from 'react-icons/ai'

const MeetingCalender = ({onClose}) => {

    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
    };

    // useCalendlyEventListener({
    //     onEventScheduled: (e) => {
    //         if (e.data.event === 'calendly.event_scheduled') {
    //             setTimeout(() => {
    //                 onClose();
    //             }, 1000)
    //         }
    //     },
    // });


    return (
        <section className={styles.beginning}>
            <Container className={styles.beginningContainer}>
                <div className={styles.closeBtn}>
                    <AiFillCloseCircle size={40} color="black" onClick={onClose}/>
                </div>
                <div className={styles.beginningContent}>
                    <InlineWidget
                        url={"https://calendly.com/appinvento2020/30min"}
                        pageSettings={pageSettings}
                        styles={{height: 660, margin: '0 !important'}}
                    />
                </div>
            </Container>
        </section>
    );
}

export default MeetingCalender;
