import * as React from "react"

function AppInventoBlackTheme(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="90 388 800 900"
      style={{
        enableBackground: "new 0 0 1000 1000",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n    .st0 {\n      fill: url(#SVGID_1_);\n    }\n\n    .st1 {\n      fill: url(#SVGID_2_);\n    }\n\n    .st2 {\n      fill: #121212;\n      stroke: url(#SVGID_3_);\n      stroke-width: 11;\n      stroke-miterlimit: 10;\n    }\n\n    .st3 {\n      fill: url(#SVGID_4_);\n    }\n\n    .st4 {\n      fill: #FFFFFF;\n    }\n\n    .st5 {\n      fill: url(#SVGID_5_);\n    }\n  "
        }
      </style>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={134.3199}
          y1={523.2393}
          x2={233.7652}
          y2={445.2319}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#94CE98",
            }}
          />
          <stop
            offset={0.2588}
            style={{
              stopColor: "#89C99F",
            }}
          />
          <stop
            offset={0.724}
            style={{
              stopColor: "#6BBFB2",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#51B9BE",
            }}
          />
        </linearGradient>
        <polygon
          className="st0"
          points="191.81,456.07 141.33,485.56 191.81,515.07 191.81,530.25 117.76,487.05 117.75,484.13 191.81,440.92  "
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={-4590.1929}
          y1={528.0958}
          x2={-4684.2979}
          y2={443.7231}
          gradientTransform="matrix(-1 0 0 1 -4347.0146 0)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#94CE98",
            }}
          />
          <stop
            offset={0.2354}
            style={{
              stopColor: "#8CCA9D",
            }}
          />
          <stop
            offset={0.6264}
            style={{
              stopColor: "#74C1AD",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#51B9BE",
            }}
          />
        </linearGradient>
        <polygon
          className="st1"
          points="277.66,456.07 328.14,485.56 277.66,515.07 277.66,530.25 351.71,487.05 351.72,484.13 277.66,440.92  "
        />
      </g>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1={219.4759}
        y1={514.2524}
        x2={252.433}
        y2={457.1691}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2209}
          style={{
            stopColor: "#8ECB9C",
          }}
        />
        <stop
          offset={0.5406}
          style={{
            stopColor: "#7CC4A7",
          }}
        />
        <stop
          offset={0.9187}
          style={{
            stopColor: "#5BBBBA",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <line className="st2" x1={211.47} y1={507.65} x2={260.44} y2={463.77} />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1={215.6077}
        y1={518.8392}
        x2={253.8604}
        y2={452.5837}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2209}
          style={{
            stopColor: "#8ECB9C",
          }}
        />
        <stop
          offset={0.5406}
          style={{
            stopColor: "#7CC4A7",
          }}
        />
        <stop
          offset={0.9187}
          style={{
            stopColor: "#5BBBBA",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <path
        className="st3"
        d="M234.73,447.46c-21.12,0-38.24,17.13-38.24,38.25c0,0.67,0.02,1.35,0.05,2.01 c1.04,20.19,17.74,36.24,38.19,36.24c20.45,0,37.15-16.05,38.2-36.24c0.03-0.66,0.05-1.33,0.05-2.01 C272.98,464.59,255.85,447.46,234.73,447.46z M234.62,512.97c-14.41,0-26.22-11.12-27.31-25.26c-0.05-0.71-0.08-1.42-0.08-2.13 c0-15.12,12.27-27.39,27.39-27.39c15.13,0,27.39,12.26,27.39,27.39c0,0.72-0.03,1.43-0.08,2.13 C260.84,501.85,249.03,512.97,234.62,512.97z"
      />
      <g>
        <path
          className="st4"
          d="M427.31,497.72c-4.29,7.88-12.48,13.24-21.97,13.24c-13.78,0-24.96-11.32-24.96-25.27s11.18-25.26,24.96-25.26 s24.96,11.32,24.96,25.26v25.27h-3V497.72z M405.34,507.93c12.08,0,21.97-10,21.97-22.23s-9.89-22.23-21.97-22.23 s-21.97,10-21.97,22.23S393.26,507.93,405.34,507.93z"
        />
        <path
          className="st4"
          d="M440.29,460.43h3v13.24c4.19-7.88,12.48-13.24,21.97-13.24c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.27-24.96,25.27c-9.49,0-17.77-5.36-21.97-13.24v38.5h-3V460.43z M465.26,507.93c12.08,0,21.97-10,21.97-22.23 s-9.89-22.23-21.97-22.23c-11.78,0-21.97,9.5-21.97,22.23C443.29,497.93,453.17,507.93,465.26,507.93z"
        />
        <path
          className="st4"
          d="M500.21,460.43h3v13.24c4.19-7.88,12.48-13.24,21.97-13.24c13.78,0,24.96,11.32,24.96,25.26 s-11.18,25.27-24.96,25.27c-9.49,0-17.77-5.36-21.97-13.24v38.5h-3V460.43z M525.17,507.93c12.08,0,21.97-10,21.97-22.23 s-9.89-22.23-21.97-22.23c-11.78,0-21.97,9.5-21.97,22.23C503.2,497.93,513.09,507.93,525.17,507.93z"
        />
      </g>
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1={607.3319}
        y1={479.2644}
        x2={846.2621}
        y2={464.6207}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#94CE98",
          }}
        />
        <stop
          offset={0.2151}
          style={{
            stopColor: "#87C8A0",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#51B9BE",
          }}
        />
      </linearGradient>
      <path
        className="st5"
        d="M564.89,445.28h-5.99v-10.11h5.99V445.28z M558.9,460.43h5.99v50.53h-5.99V460.43z M574.78,460.43h5.99v8.89 c4.59-5.46,11.39-8.89,18.98-8.89c13.78,0,24.97,11.32,24.97,25.26v25.26h-5.99V485.7c0-10.61-8.49-19.2-18.97-19.2 c-10.29,0-18.98,9-18.98,19.2v25.26h-5.99V460.43z M653.07,503.58l18.98-43.15h6.49l-22.17,50.53h-6.49l-22.17-50.53h6.39 L653.07,503.58z M706.49,504.9c5.19,0,9.99-2.12,13.38-5.56l4.29,4.14c-4.49,4.65-10.78,7.48-17.68,7.48 c-13.78,0-24.97-11.32-24.97-25.26c0-13.95,11.18-25.26,24.97-25.26c13.78,0,24.97,11.32,24.97,25.26v3.03h-43.74 C689.22,497.93,697.01,504.9,706.49,504.9z M687.72,482.67h37.45c-1.4-9.2-9.19-16.17-18.67-16.17 C697.01,466.5,689.22,473.47,687.72,482.67z M741.44,460.43h5.99v8.89c4.59-5.46,11.38-8.89,18.97-8.89 c13.78,0,24.97,11.32,24.97,25.26v25.26h-5.99V485.7c0-10.61-8.49-19.2-18.97-19.2c-10.29,0-18.97,9-18.97,19.2v25.26h-5.99V460.43z  M801.36,435.17h5.99v25.26h21.97v6.06h-21.97v44.46h-5.99V435.17z M857.28,460.43c13.78,0,24.97,11.32,24.97,25.26 c0,13.95-11.18,25.26-24.97,25.26c-13.78,0-24.97-11.32-24.97-25.26C832.31,471.75,843.5,460.43,857.28,460.43z M857.28,504.9 c10.49,0,18.97-8.59,18.97-19.2c0-10.61-8.49-19.2-18.97-19.2c-10.49,0-18.98,8.59-18.98,19.2 C838.3,496.31,846.79,504.9,857.28,504.9z"
      />
    </svg>
  )
}

export default React.memo(AppInventoBlackTheme);
