// extracted by mini-css-extract-plugin
export var accent = "styles-module--accent--be54f";
export var alignCenter = "styles-module--alignCenter--c9600";
export var alignLeft = "styles-module--alignLeft--ea607";
export var bold = "styles-module--bold--01e56";
export var camioPrimary = "styles-module--camioPrimary--08a67";
export var camioSecondary = "styles-module--camioSecondary--22ce2";
export var danger = "styles-module--danger--b6627";
export var dark = "styles-module--dark--18226";
export var defaultColor = "styles-module--defaultColor--2596d";
export var findHeliBluePrimary = "styles-module--findHeliBluePrimary--7aa46";
export var findHeliYellow = "styles-module--findHeliYellow--53cbf";
export var lockitronPrimary = "styles-module--lockitronPrimary--1234e";
export var lockitronSecondary = "styles-module--lockitronSecondary--e6fe4";
export var primary = "styles-module--primary--868e7";
export var suGray = "styles-module--suGray--67c76";
export var suOrange = "styles-module--suOrange--dc0a4";
export var suPink = "styles-module--suPink--11f06";
export var success = "styles-module--success--67108";
export var text = "styles-module--text--1306a";
export var text12 = "styles-module--text12--14cf4";
export var text14 = "styles-module--text14--c8779";
export var text16 = "styles-module--text16--e962f";
export var text18 = "styles-module--text18--d7261";
export var text20 = "styles-module--text20--6ecde";
export var text22 = "styles-module--text22--eab2f";
export var text24 = "styles-module--text24--77731";
export var text28 = "styles-module--text28--7776f";
export var text32 = "styles-module--text32--db4d1";
export var text48 = "styles-module--text48--0e74e";
export var text56 = "styles-module--text56--6ccbf";
export var vppGreenAccent = "styles-module--vppGreenAccent--1646a";
export var white = "styles-module--white--15a6a";
export var woovitPrimary = "styles-module--woovitPrimary--3b69e";