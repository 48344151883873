import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss';

const Container = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Container.defaultProps = {
  className: undefined,
}

export default Container
