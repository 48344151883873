import React from 'react';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

const getOption = (value, options, isMulti) => {
  if (isMulti) {
    return options.filter((option) => value.includes(option.value) || value.includes(option.id));
  } else {
    return options.find((option) => option.value === value || option.id === value);
  }
};

const getSelectedValue = (isAsync, isMulti, options, value) => {
  if (isAsync) {
    if (value && Object.keys(value).length) {
      if (value) return value;
      else return null;
    } else {
      return null;
    }
  } else if (value) {
    return getOption(value, options, isMulti);
  } else {
    return null;
  }
};

const SelectBox = ({
                        width,
                        size,
                        value,
                        options,
                        onChange,
                        style,
                        placeholder,
                        isMulti,
                        className,
                        label,
                        labelSmall,
                        selectClassName,
                        classNamePrefix,
                        isAsync,
                        isCreatable,
                        onCreateOption,
                        horizontal,
                        loadOptions,
                        getOptionLabel,
                        getOptionValue,
                        isClearable,
                        isDisabled,
                        error,
                        ...rest
                      }) => {
  const selected = getSelectedValue(isAsync, isMulti, options, value);
  const Tag = isAsync ? AsyncSelect : isCreatable ? CreatableSelect : ReactSelect;
  const { Option, SingleValue, MultiValue } = components;

  const CustomSingleValue = (props) => (
    <SingleValue {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </SingleValue>
  );

  const CustomMultiValue = (props) => (
    <MultiValue {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </MultiValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </Option>
  );

  return (
    <div>
      <div style={{ marginBottom: '0.4rem' }}>
        {label && (
          <label>
            {label}
            {labelSmall && <small>{labelSmall}</small>}
          </label>
        )}
      </div>
      <Tag
        size={size}
        value={selected}
        options={options}
        onChange={(opt) => {
          const getValue = (opt) => (opt ? (isAsync ? opt : opt.value ? opt.value : opt.id) : null);
          const value = isMulti ? opt && opt.map(getValue) : getValue(opt);
          onChange(value);
        }}
        styles={styles(width, size)}
        components={{ DropdownIndicator, Option: IconOption, SingleValue: CustomSingleValue, MultiValue: CustomMultiValue }}
        // style={style}
        placeholder={placeholder}
        className={`form-field react-select ${selectClassName ? selectClassName : ''} ${isDisabled ? 'disabled' : ''}`}
        classNamePrefix={`react-select`}
        isMulti={isMulti ? isMulti : false}
        loadOptions={isAsync ? loadOptions : null}
        getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.label}
        getOptionValue={getOptionValue ? getOptionValue : (option) => option.value}
        isClearable={isClearable}
        isDisabled={isDisabled ? isDisabled : false}
        onCreateOption={isCreatable && onCreateOption ? onCreateOption : null}
        // menuIsOpen={true}
        {...rest}
      />

      {error && <div>{error}</div>}
    </div>
  );
};

SelectBox.propTypes = {
  width: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * value:
   * it contains the select-box values.
   */
  options: PropTypes.array,
  /**
   * options:
   * It contains all the option related to selected component.
   *
   */
  onChange: PropTypes.func,
  /**
   * onChange:
   * Do: Set the value of the component.
   */
  style: PropTypes.object,
  /**
   * style:
   * Style of selected component.
   */
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  labelSmall: PropTypes.string,
  selectClassName: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  horizontal: PropTypes.bool,
  loadOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  /**
   * getOptionLabel:
   * Get the label for the components.
   */
  getOptionValue: PropTypes.func,
  /**
   * getOptionValue:
   * Get the options for the components.
   */
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
  }),
};
export default SelectBox

export const styles = (width, size) => ({
  container: (base) => ({
    ...base,
    width: width || '100%',
    // color: style.color.font,
  }),
  menu: (base) => ({
    ...base,
    border: '1px solid',
    borderColor: '#c4c3c3',
    background: 'white',
    zIndex: 9999,
  }),
});

export const DropdownIndicator = (props) => {
  const { menuIsOpen, size } = props.selectProps;
  return (
    components.DropdownIndicator && (
      <div className={menuIsOpen ? 'menu-is-open' : ''}>
        <components.DropdownIndicator {...props}>
          {menuIsOpen ? <AiOutlineDown /> : <AiOutlineRight />}
        </components.DropdownIndicator>
      </div>
    )
  );
};
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
    size: PropTypes.string,
  }),
};
