import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import BigArrowRight from '_images/svgs/big-arrow-right.inline'

import * as styles from './styles.module.scss';

const Arrow = ({ className }) => (
  <div className={classNames(styles.ctaArrow, className)}>
    <span className={styles.ctaArrowLine} />
    <BigArrowRight className={styles.ctaArrowIcon} />
  </div>
)

Arrow.propTypes = {
  className: PropTypes.string,
}

Arrow.defaultProps = {
  className: undefined,
}

export default Arrow
