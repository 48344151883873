import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss';

const HEADING_LEVELS = ['h1', 'h2', 'h3', 'h4', 'h5']
const HEADING_COLORS = [
  'default',
  'white',
  'primary',
  'blueAccent',
  'findHeliBluePrimary',
  'vppBluePrimary',
  'lockitronAccent',
  'skyroamSecondary',
  'camioPrimary',
  'camioSecondary',
  'eqiPrimary',
  'eqiAccent',
  'exmoxPrimary',
  'woovitPrimary',
  'woovitSecondary',
  'woovitAccent',
]

const [h1] = HEADING_LEVELS
const [defaultColor] = HEADING_COLORS

const Heading = ({ children, type, className, color, bold, fixedSize, ...props }) => {
  const componentProps = {
    className: classNames(
      styles.headings,
      styles[type],
      { [styles.bold]: bold, [styles.responsive]: !fixedSize },
      color !== 'default' ? styles[color] : '',
      className
    ),
    ...props,
  }

  return React.createElement(type, { ...componentProps }, children)
}

Heading.propTypes = {
  type: PropTypes.oneOf(HEADING_LEVELS),
  color: PropTypes.oneOf(HEADING_COLORS),
  children: PropTypes.node,
  className: PropTypes.string,
  bold: PropTypes.bool,
  fixedSize: PropTypes.bool,
}

Heading.defaultProps = {
  type: h1,
  color: defaultColor,
  children: undefined,
  className: undefined,
  bold: false,
  fixedSize: false,
}

export default Heading
