import React from 'react'
import { RotatingLines } from 'react-loader-spinner';
import * as styles from './styles.module.scss';

const ScreenLoader = () => {
  return (
    <div className={styles.screenLoader}>
      <RotatingLines strokeColor="#ffffff" width={80} ariaLabel="loading" />
    </div>
  )
}

export default ScreenLoader
