import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import Modal from 'react-modal';
import MeetingCalender from "../../pages/book-demo/meetingCalender/meetingCalender";
import PropTypes from "prop-types";
import * as styles from './styles.module.scss';

export const BookDemoContext = createContext({});

export const useBookDemoContextData = () => {
    return useContext(BookDemoContext);
};

const BookDemoContextProvider = (props) => {
    const {children} = props;
    const [isBookDemoModalOpen, setIsBookDemoModalOpen] = useState(false);

    const handleOpenBookDemoModel = useCallback(() => {
        setIsBookDemoModalOpen(true);
    }, [setIsBookDemoModalOpen]);

    const handleCloseBookDemoModel = useCallback(() => {
        setIsBookDemoModalOpen(false);
    }, [setIsBookDemoModalOpen]);

    const bookDemoContextProviderValue = useMemo(
        () => ({
            handleOpenBookDemoModel,
            handleCloseBookDemoModel
        }),
        [],
    );

    return (
        <BookDemoContext.Provider value={bookDemoContextProviderValue}>
            {children}
            {isBookDemoModalOpen && (
                <Modal isOpen={isBookDemoModalOpen} onRequestClose={handleCloseBookDemoModel} className={styles.modal}
                       overlayClassName={styles.overlay}>
                    <MeetingCalender onClose={handleCloseBookDemoModel}/>
                </Modal>
            )}
        </BookDemoContext.Provider>
    );
};

BookDemoContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}


export default BookDemoContextProvider;
